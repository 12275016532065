.App {
  text-align: center;
}

.name{
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color:white;
  text-shadow: -1px 1px 0 #000000,
  1px 1px 0 #000000,
  1px -1px 0 #000000,
  -1px -1px 0 #000000;
}
.btn {
  margin: 1.3rem 0.4rem;
  font-family: "Archivo Black", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.2rem;
  display: inline-block;
  width: 95vw;
  padding: 1.2rem 0;
  background-color: transparent;
  color: white;
  /* text-shadow: -1px 1px 0 #000000,
  1px 1px 0 #000000,
  1px -1px 0 #000000,
  -1px -1px 0 #000000; */
  animation: pulseBorder 0.7s ease-in-out infinite alternate;
  text-align: center;
  text-decoration: none;
  border: 0 solid #5d9edf;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

@keyframes pulsate {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #fff,
      0 0 80px #fff, 0 0 90px #fff;
  }
  0% {
    text-shadow: 0 0 4px #fff, 0 0 10px #fff, 0 0 18px #fff, 0 0 38px #fff,
      0 0 73px #fff, 0 0 80px #fff, 0 0 94px #fff, 0 0 140px #fff;
  }
}

.btn::before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.377);
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  z-index: 1;
  /* animation: slide 4s ease-in-out infinite;  */
}

.btn span {
  position: relative;
  z-index: 2;
}

@keyframes slide {
  0% {
    left: -100%;
  }
  30% {
    left: 100%; /* Changed animation direction */
  }
  60% {
    left: -100%; /* Changed animation direction */
  }
  100% {
    left: 100%;
  }
}

.visual {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0.1rem;
}

.avatar {
  display: block;
  width: 50vw;
  border-radius: 50%;
  height: auto;
  border: 0.2rem solid white;
  /* animation: pulseBorder 2s infinite alternate;  */
}

@keyframes pulseBorder {
  0% {
    border: 0.4rem solid rgba(255, 238, 0, 0.596);
  }
  20% {
    border: 0.4rem solid rgba(253, 2, 2, 0.63);
  }
  40% {
    border: 0.4rem solid rgba(4, 0, 255, 0.548);
  }
  60% {
    border: 0.4rem solid rgba(43, 255, 0, 0.521);
  }
  80% {
    border: 0.4rem solid rgba(255, 0, 255, 0.514);
  }
  100% {
    border: 0.4rem solid rgba(0, 255, 242, 0.493);
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

.accurate {
  margin: 0.5rem 0;
  font-size: 1rem;
  font-family: "Archivo Black", sans-serif;
  font-weight: 800;
  font-style: normal;
  color: red;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.6rem;
  width: 90vw;
  margin: 1rem auto;
}

.join {
  font-size: 1.5rem;
  font-weight: bolder;
  font-family: Cambria, Cochin;
  color: #ffffff;
  margin: 0.5rem 0
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1rem;
  background-color: black;
  width: 100%;
}

.button img {
  height: 2.5rem;
  width: 2.5rem;
  display: inline;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}

.button div {
  margin-left: 10px;
}

.button-pulse {
  position: relative;
}

.button-pulse button {
  background-color: #333; /* initial background color */
  color: #fff; /* initial text color */
  border: 3px solid white;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  position: relative;
  overflow: hidden;
}

.button-pulse button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(45deg, #0033ff, #00ff00, #00ffff, #ff0000); */
  background-size: 200% 200%;
  z-index: 2;
  opacity: 0;
  animation: pulse-background 4s infinite;
}

.button-pulse button::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: 2px solid #ff5500; /* neon color for border */
  z-index: 2;
  opacity: 0;
  animation: pulse-border 2s infinite;
}

@keyframes pulse-background {
  0% {
    opacity: 0;
    transform: scale(1);
    background-position: 0% 50%;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
    background-position: 100% 50%;
  }
  100% {
    opacity: 0;
    transform: scale(1);
    background-position: 0% 50%;
  }
}

@keyframes pulse-border {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
