body {
  margin: 0;
  padding: 0;
  background-color: rgb(0, 0, 0);
  text-align: center;
  width: 100vw;
  height: 95vh;
  padding-top: 1rem;
  color: rgb(255, 255, 255);

}